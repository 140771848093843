.resume {
    position: relative;
    min-height: 100vh;
    padding: 10rem 9%;
    color: var(--text-color);
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

#bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1;
}

.resume-content {
    position: relative;
    max-width: 800px;
    margin: auto;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.6rem;
    line-height: 1.6;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 2;
    padding: 2rem;
    text-align: left; 
}

.download-button {
    display: block;
    margin: 2rem auto 0; 
    padding: 1rem 2.8rem;
    background: var(--main-color);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--main-color);
    font-size: 1.6rem;
    color: var(--bg-color);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.55s ease, transform 0.3s ease;
    text-align: center;
    cursor: pointer;
    z-index: 2;
}

.download-button:hover {
    box-shadow: none;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .resume-content {
        font-size: 1.4rem;
    }

    .download-button {
        font-size: 1.4rem;
        padding: 0.8rem 2rem;
    }
}

@media (max-width: 480px) {
    .resume-content {
        font-size: 1.2rem;
    }

    .download-button {
        font-size: 1.2rem;
        padding: 0.6rem 1.8rem;
    }
}