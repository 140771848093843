@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Nunito', sans-serif;
}

:root {
    --bg-color: #191f36;
    --snd-bg-color: #262B40;
    --text-color: #fff;
    --main-color: #59B2F4;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    background: var(--bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo {
    font-size: 2.5rem;
    color: var(--text-color);
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.logo:hover {
    border-bottom: 3px solid var(--main-color);
}

.navbar a {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-left: 4rem;
    font-weight: 700;
    transition: 0.3s ease-in-out;
}

.navbar a:hover {
    border-bottom: 3px solid var(--main-color);
}

.navbar a.active {
    border-bottom: 3px solid transparent;
}

.navbar a.active:hover {
    border-bottom-color: var(--main-color);
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
    display: none;
}

.home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-content h3 {
    font-size: 3.2rem;
    font-weight: 700;
    display: inline;  
    vertical-align: middle; 
}

.text-animation {
    display: inline;  
    font-size: 3.2rem;  
    font-weight: 700;  
    position: relative;
    white-space: nowrap;
    overflow: hidden;
}

.text-animation::after {
    content: '';
    background-color: var(--main-color);
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    animation: blink 1s infinite;
}

.home-content h3:nth-of-type(2) {
    margin-bottom: 2rem;
}

span {
    color: var(--main-color);
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
}

.home-img img {
    width: 25vw;
    animation: floatImage 4s ease-in-out infinite;
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2.4rem);
    }
    100% {
        transform: translateY(0);
    }
}

.home-content p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.social-media {
    text-align: center;
    margin-bottom: 2rem;
}

.social-media a {
    font-size: 15px;
    color: var(--main-color);
    border: 2px solid var(--main-color);
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 10px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.social-media a:hover {
    transform: scale(1.2) translateY(-10px);
    background-color: var(--main-color);
    color: black;
    box-shadow: 0 0 25px var(--main-color);
}

.btn {
    display: inline-block;
    padding: 1rem 2.8rem;
    background: var(--main-color);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--main-color);
    font-size: 1.6rem;
    color: var(--bg-color);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.55s ease;
    margin-bottom: 2rem;
    cursor: pointer;
}

.btn:hover {
    box-shadow: none;
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--snd-bg-color);
}

.about-img img {
    width: 35vw;
}

.heading {
    text-align: center;
    font-size: 4.5rem;
}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
}

.about-content h3 {
    font-size: 2.6rem;
}

.about-content p {
    font-size: 1.6rem;
    margin: 2rem 0 3rem;
}

.education-bg {
    background-image: url('../public/img/edubg.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    position: relative;
    z-index: 0;
}

.education-bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    backdrop-filter: blur(0px);
    transition: backdrop-filter 0.5s ease;
    z-index: -1;
}

.education-bg.in-view:before {
    backdrop-filter: blur(5px);
}

.education-bg .timeline-content {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}

.heading {
    font-size: 8rem;
    text-align: center;
    margin: 5rem 0;
}

.education h2 {
    margin-bottom: 5rem;
}

@keyframes floatItem {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.timeline-items {
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.timeline-items::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: var(--main-color);
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
    animation: floatItem 4s ease-in-out infinite; 
}

.timeline-item:last-child {
    margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
    padding-right: calc(50% + 30px);
    text-align: right;
    float: left;
}

.timeline-item:nth-child(even) {
    padding-left: calc(50% + 30px);
    float: right; 
}

.timeline-dot {
    height: 21px;
    width: 21px;
    background-color: var (--main-color);
    box-shadow: 0 0 25px var(--main-color), 
                0 0 50px var(--main-color);
    position: absolute;
    left: calc(50% - 10.5px); 
    border-radius: 50%;
    top: 10px;
}

.timeline-date {
    font-size: 20px;
    font-weight: 800;
    color: white;
    margin: 6px 0 15px;
}

.timeline-content {
    background-color: var(--bg-color);
    border: 3px solid var(--main-color);
    padding: 30px 50px;
    border-radius: 4rem;
    box-shadow: 0 0 10px var(--main-color);
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.timeline-content:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px var(--main-color);
}

.timeline-content h3 {
    font-size: 20px;
    color: white;
    margin: 0 0 10px;
    font-weight: 500;
}

.timeline-content p {
    color: white;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
}

.projects {
    position: relative;
    padding: 50px 0;
    overflow: hidden;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

#bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.overlay-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    padding: 20px;
}

.projects-box {
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.projects .heading {
    margin-bottom: 5rem;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.project-item {
    min-height: 550px;
    max-width: 450px;
    background: rgba(0, 0, 0, 0.7);
    border: 3px solid rgba(238, 238, 238, 0.2);
    border-radius: 2rem;
    margin: 0 2rem;
    padding: 30px 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    color: white;
    transition: 0.4s ease-in-out;
}

.project-item:hover {
    border: 3px solid var(--main-color);
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 0 50px var(--main-color);
}

.project-item h2 {
    font-size: 2.8rem;
}

.project-item p {
    font-size: 1.3rem;
}

.project-item img {
    width: 15rem;
    border-radius: 50%;
    border: 3px solid var(--main-color);
    box-shadow: 0 0 25px var(--main-color);
}

.contact {
    position: relative;
    padding: 50px 0;
    overflow: hidden;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

#bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
    z-index: 1;
}

.overlay-content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 20px;
}

.contact .heading {
    margin-bottom: 5rem;
}

.contact-content {
    font-size: 3rem;
    line-height: 2rem;
}

.animated-text {
    display: inline-block;
    position: relative;
    margin-bottom: 2rem;
    animation: textAnimate 3s linear infinite;
}

@keyframes textAnimate {
    0% {
        color: var(--main-color);
        transform: translateY(0);
    }
    50% {
        color: var(--snd-bg-color);
        transform: translateY(-10px);
    }
    100% {
        color: var(--main-color);
        transform: translateY(0);
    }
}

.contact-content a {
    color: var(--main-color);
    text-decoration: none;
    transition: color 0.3s;
}

.contact-content a:hover {
    color: var(--snd-bg-color);
}

.contact-content i {
    margin-right: 8px;
    font-size: 1.5rem;
    vertical-align: middle;
}

footer {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 40px 0;
    background-color: var(--snd-bg-color);
}

footer .social {
    text-align: center;
    padding-bottom: 25px;
    color: var(--main-color);
}

footer .social a {
    font-size: 25px;
    color: var(--main-color);
    border: 2px solid var(--main-color);
    width: 42px;
    height: 42px;
    line-height: 42px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 10px;
    transition: 0.3s ease-in-out;
}

footer .social a:hover {
    transform: scale(1.2) translateY(-10px);
    background-color: var(--main-color);
    color: black;
    box-shadow: 0 0 25px var(--main-color);
}

footer ul {
    margin-top: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
    text-align: center;
}

footer ul li a {
    color: white;
    border-bottom: 3px solid transparent;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

footer ul li a:hover {
    border-bottom: 3px solid var(--main-color);
}

footer ul li {
    display: inline-block;
    padding: 0 15px;
}

footer .copyright {
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
    color: white;
}

@media (max-width: 1285px) {
    #menu-icon {
        display: block;
        cursor: pointer;
    }

    .navbar {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        padding: 1rem 3%;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(20px);
        border-bottom-left-radius: 2rem;
        border-left: 2px solid var(--main-color);
        border-bottom: 2px solid var(--main-color);
        display: none;
        flex-direction: column;
        align-items: flex-start;
        z-index: 100;
    }

    .navbar.active {
        display: flex;
    }

    .navbar a {
        display: block;
        font-size: 2rem;
        margin: 1rem 0;
        color: white;
    }

    .home {
        flex-direction: column-reverse;
        margin: 5rem 4rem;
    }

    .home-content h3 {
        font-size: 2.6rem;
    }

    .home-content h1 {
        font-size: 8rem;
        margin-top: 3rem;
    }

    .home-content p {
        max-width: 600px;
        margin: 0 auto;
    }

    .home-img img {
        width: 56vw;
    }

    .projects h2 {
        margin-bottom: 3rem;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 1rem 5%;
    }

    .logo {
        font-size: 2rem;
    }

    .navbar a {
        font-size: 1.5rem;
        margin-left: 2rem;
        cursor: pointer;
    }

    .home-content h3 {
        font-size: 2rem;
    }

    .home-content h1 {
        font-size: 5rem;
    }

    .home-img img {
        width: 75vw;
    }

    .about-img img {
        width: 75vw;
    }

    .about-content h2 {
        font-size: 3rem;
    }

    .about-content h3 {
        font-size: 2rem;
    }

    .about-content p {
        font-size: 1.4rem;
    }

    .timeline-items {
        display: block;
    }

    .timeline-item {
        padding: 0 2rem;
    }

    .timeline-item:nth-child(odd),
    .timeline-item:nth-child(even) {
        float: none;
        text-align: left;
        padding: 0;
    }

    .timeline-dot {
        display: none;
    }

    .timeline-content {
        padding: 2rem;
    }

    .wrapper {
        grid-template-columns: 1fr;
    }

    .project-item {
        min-height: auto;
        max-width: 100%;
        margin: 0 1rem;
        padding: 2rem;
    }

    .contact-content {
        font-size: 2rem;
    }

    footer ul li {
        display: block;
        padding: 0.5rem 0;
    }
}
